var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"d-flex align-items-center justify-content-center login-background"},[_c('b-card',{staticClass:"login-card mx-3 mx-sm-0",attrs:{"border-variant":"light"}},[_c('div',{staticClass:"d-flex justify-content-between mb-3"},[_c('b-card-title',{staticClass:"mb-0 mr-4"},[_vm._v(" "+_vm._s(_vm.newUser ? _vm.$t("resetPasswordForm.titleNew") : _vm.$t("resetPasswordForm.title"))+" ")]),_c('img',{staticClass:"logo",attrs:{"src":_vm.logo,"alt":"Qognite Logo"}})],1),_c('ValidationObserver',{ref:"observer",scopedSlots:_vm._u([{key:"default",fn:function(ref){
var handleSubmit = ref.handleSubmit;
return [_c('b-form',{on:{"submit":function($event){$event.preventDefault();return handleSubmit(_vm.setNewPassword)}}},[_c('ValidationProvider',{attrs:{"name":"New Password","rules":"required|min:6|password:@Confirmation"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
var valid = ref.valid;
return [_c('b-form-group',{attrs:{"id":"group-password","label-for":"new-password"},scopedSlots:_vm._u([{key:"label",fn:function(){return [_vm._v(" "+_vm._s(_vm.$t("resetPasswordForm.newPassword"))+" "),_c('span',{staticClass:"text-danger"},[_vm._v("*")])]},proxy:true}],null,true)},[_c('b-form-input',{attrs:{"id":"new-password","state":_vm.getValidationState(errors, valid),"autocomplete":"new-password","type":"password"},model:{value:(_vm.form.new),callback:function ($$v) {_vm.$set(_vm.form, "new", $$v)},expression:"form.new"}}),_c('b-form-invalid-feedback',{attrs:{"id":"live-feedback-password"}},[_vm._v(" "+_vm._s(errors[0])+" ")])],1)]}}],null,true)}),_c('ValidationProvider',{attrs:{"name":"Confirmation","rules":"required|min:6|password:@New Password"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
var valid = ref.valid;
var invalid = ref.invalid;
return [_c('b-form-group',{attrs:{"id":"group-password-confirm","label-for":"password-confirm"},scopedSlots:_vm._u([{key:"label",fn:function(){return [_vm._v(" "+_vm._s(_vm.$t("resetPasswordForm.confirmPassword"))+" "),_c('span',{staticClass:"text-danger"},[_vm._v("*")])]},proxy:true}],null,true)},[_c('b-form-input',{attrs:{"id":"password-confirm","state":_vm.getValidationState(errors, valid),"autocomplete":"new-password","type":"password"},model:{value:(_vm.form.confirm),callback:function ($$v) {_vm.$set(_vm.form, "confirm", $$v)},expression:"form.confirm"}}),_c('b-form-invalid-feedback',{attrs:{"id":"live-feedback-password-confirm"}},[_vm._v(" "+_vm._s(errors[0])+" ")])],1),_c('div',{staticClass:"d-flex align-items-center justify-content-between"},[_c('b-link',{attrs:{"to":"/login"}},[_vm._v(" "+_vm._s(_vm.$t("resetPasswordEmail.back"))+" ")]),_c('b-button',{staticClass:"gradient-button-outline",attrs:{"disabled":invalid || _vm.loading,"pill":"","type":"submit"}},[_vm._v(" "+_vm._s(_vm.newUser ? _vm.$t("resetPasswordForm.set") : _vm.$t("resetPasswordForm.reset"))+" ")])],1)]}}],null,true)})],1)]}}])})],1),_c('Footer')],1)}
var staticRenderFns = []

export { render, staticRenderFns }